.general-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.upcoming-cities{
    display: flex;
    justify-content: center;
    margin-bottom: .1rem;
  }

  .dish-count{
    display: flex;
    justify-content: center;
    margin-bottom: .1rem;
  }