.wrapper-fun-budget {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  height: 100vh;
}

.carousel-image {
  max-width: auto;
  height: 30%;
  cursor: pointer;
  transition: transform 0.5s ease;
  border-radius: 20px;
}

.fun-stop-button{
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: #f44336;
  border: 2px solid #f44336;
  border-radius: 20px;
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: auto;
  font-weight: bold;
}

.dish-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.restart-carousel-button,
.go-to-form-button {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #3e3e3e;
  color: #fff;
  font-size: 1rem;
}

.restart-carousel-button:hover,
.go-to-form-button:hover {
  background-color: #5c5c5c;
}

.row-direction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  height: 100vh;
}

.fun-dish-title{
  font-size: 1.5rem;
  font-weight: bold;
}

.fun-budget-price{
  font-size: 1.2rem;
  font-weight: bold;
}

.show-description{
  color: #07a;
  text-decoration: underline;
  cursor: pointer;
}

.restaurant-info-fun-section{
  display: flex;
  /* width: 100%; */
}

.fun-restaurnat-name{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 100%;
  align-items: center;
}

.fun-take-me-there{
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: #f44336;
  border: 1px solid #f44336;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 3rem;
  font-weight: bold;
}

.fun-go-to-form-screen-button{
  margin-bottom: .5rem;
}



@media only screen and (min-width: 1300px){
  .carousel-image {
    max-width: 50%;
    height: 50%;
  }

  .wrapper-fun-budget {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    height: 100vh;
}

.fun-dish-title{
  font-size: 3rem;
  font-weight: bold;
}
.dish-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.row-direction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  height: 100vh;
}

.restaurant-info-fun-section {
  display: flex;
  /* width: 100%; */
}

.fun-restaurnat-name {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  /* width: 100%; */
  align-items: center;
}

.fun-take-me-there {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: #f44336;
  border: 1px solid #f44336;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 3rem;
  font-weight: bold;
  width: 15rem;
}

.fun-budget-price{
  font-size: 2rem;
  font-weight: bold;
}
}

@media screen and (min-width: 768px) {
  .fun-take-me-there{
    width: 20rem;
  }

  .fun-description{
    width: 70%;
  }
}