.disclaimer-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  flex-direction: column;
  }
  
  .disclaimer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
  }
  
  .disclaimer-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .disclaimer-second-text{
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .disclaimer-button {
    padding: 10px 20px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .privacy-policy-disclaimer{
    bottom: 1rem;
    text-decoration: underline;
    color: black;
  }

/* For screens larger than 768px */
@media only screen and (min-width: 768px) {
  .disclaimer-text{
    width: 40%;
  }

  .disclaimer-second-text{
    width: 40%;
  }

    }