.unselected {
  display: flex;
  justify-content: center;
  border-radius: 20px;
    color: black;
    background-color: white;
    border: 1px solid #f44336;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 6rem;
    margin: .2rem;
  }
  
 .selected {
  display: flex;
  justify-content: center;
  border-radius: 20px;
    background-color: #f44336;
    color: white;
    border: 1px solid #f44336;;
    width: 6rem;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: .2rem;
  font-weight: bold;
  }
  