.back{
    margin: 1rem 0 0 1rem;
    color: #f44336;
    font-weight: bold;
    cursor: pointer;
}

.booleansSection{
    width: 100%;
}
.booleans{
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.category{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

input{
    width: auto;
}

.fileInput{
    background-color: #f5f5f5;
    margin-bottom: -2%;
}

.descriptionForm{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.descriptionInput{
    margin-left: 0px;
    margin-bottom: 0px;
}

.restaurantCity{
    margin-top: 1rem;
}

.cuisineSelect{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
}

.cuisineForm{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

textarea{
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 10px;
    background-color: #e0e0e0;
    font-size: 1.1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.privacy-policy-dish-suggestion{
    display: flex;
    justify-content: center;
    color: black;
    padding: 1rem;
}

.descriptionInputSuggestion{
    height: 10rem;
}

.suggestionForm{
    width: auto;
}

/* For screens larger than 768px */
@media screen and (min-width: 768px) {
.new-dish-wrapper{
    display: flex;
    flex-direction: column;
}

form{
    height: 100%;
    margin-bottom: 2rem;
}

.fileInput{
    width: 100%;
}
}